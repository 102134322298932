import './App.css';
import styled from '@emotion/styled'

const Container = styled.div`
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const App = () => (
    <Container>
      Hoi!
      <br/>
      <br/>
      Mijn naam is Gijs Kruijt. Ik ben bezig met dingen te bouwen van hout, vandaar dat het bouwen van deze website nog niet af is.
      <br/>
      Wel ben ik te bereiken op 06 - 4994 1947 voor klussen.
    </Container>
  );

export default App;
